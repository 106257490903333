<template>
  <div class="view--terms-of-use">
    <content-section
      type="simple"
      :title="$localize.get('terms_of_use')"
      center-buttons
    >
      <template v-slot:content>
        <div v-html="$localize.get('terms_of_use_content')"></div>
      </template>
    </content-section>
  </div>
</template>

<script>
export default {
  name: 'TermsOfUse',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.view--terms-of-use {
  h3 {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}
</style>